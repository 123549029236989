import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 1024px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 30px;

  min-height: 70vh;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 40px;
  }
`;

export const Left = styled.div`
  width: 100%;
  max-width: 350px;

  h1 {
    font-size: 96px;
    line-height: 96px;

    color: ${({ theme }) => theme.notFound.title};
  }

  p {
    margin-top: 10px;
    font-size: 18.5px;
    line-height: 28px;
  }

  a {
    font-weight: 700;
    transition: box-shadow 0.15s linear, color 0.15s linear;

    :hover {
      box-shadow: 0px 1px white;
    }
  }

  @media (max-width: 400px) {
    p {
      max-width: 250px;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  align-self: flex-end;

  @media (max-width: 800px) {
    align-self: initial;
  }
`;
