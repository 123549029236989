import Image from 'next/image';
import Link from 'next/link';
import { Container, Left, Right } from './styles';

import HybotError from '@/images/Pages/404/hybot-error.png';

const MainSection: React.FC = () => {
  return (
    <Container>
      <Left>
        <h1>Eita!</h1>
        <p>
          Esta página não existe ou foi removida. Retorne para a{' '}
          <Link href="/" passHref>
            <a>Página inicial</a>
          </Link>
          .
        </p>
      </Left>
      <Right>
        <Image
          src={HybotError}
          alt="Imagem do HyBot(mascote da Hyerdev) indicando erro"
          quality={100}
          placeholder="blur"
        />
      </Right>
    </Container>
  );
};

export default MainSection;
